import React from "react";
import "./zeropackagemodal.css";
import axios from "axios";

function ZeroPackageModal({ isVisible, onClose, onPackageAction }) {
  if (!isVisible) return null;

  // Function to handle API calls based on the action (backup, retrieve, zero)
  const handlePackageAction = (action) => {
    let url = "";

    switch (action) {
      case "backup":
        url = "https://www.quatdashboard.com:8084/api/mainTable/backup";
        break;
      case "retrieve":
        url = "https://www.quatdashboard.com:8084/api/mainTable/restore";
        break;
      case "zero":
        if (
          !window.confirm("Are you sure you want to reset package for all?")
        ) {
          return;
        }
        url = "https://www.quatdashboard.com:8084/api/mainTable/resetall";
        break;

        case "zeroflat":
          if (
            !window.confirm("Are you sure you want to reset package for Flattrade Users?")
          ) {
            return;
          }
          url = "https://www.quatdashboard.com:8084/api/mainTable/resetflattrade";
          break;
      default:
        return;
    }

    // Call the respective API based on action
    axios
      .post(url)
      .then((response) => {
        console.log(response.data); // Log the response or handle success
        onPackageAction(action); // You can call the passed callback if needed
        alert(
          `${
            action.charAt(0).toUpperCase() + action.slice(1)
          } action completed successfully!`
        );
      })
      .catch((error) => {
        console.error("There was an error!", error); // Handle the error
        alert(`Failed to perform ${action} action.`);
      });
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Package Actions</h2>
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <div className="btn-container">
          <button
            className="btn-package"
            onClick={() => handlePackageAction("backup")}
          >
            Backup
          </button>
          <button
            className="btn-package"
            onClick={() => handlePackageAction("zero")}
          >
            Zero
          </button>
          <button
            className="btn-package"
            onClick={() => handlePackageAction("retrieve")}
          >
            Retrieve
          </button>

          <button
            className="btn-package"
            onClick={() => handlePackageAction("zeroflat")}
          >
            Zero FlatTrade
          </button>
        </div>
      </div>
    </div>
  );
}

export default ZeroPackageModal;
