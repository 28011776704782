import React, { useState } from "react";
import "./sideBar.css";
import axios from "axios";

function SideBar() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [broadcastMessage, setBroadcastMessage] = useState("");

  // Function to toggle popup visibility
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  // Function to handle message change
  const handleMessageChange = (event) => {
    setBroadcastMessage(event.target.value);
  };

  const handleAlert = () => {
    const confirmed = window.confirm(
      "Are you sure you want to send pnl alert?"
    );
    if (confirmed) {
      handleAlertPnL();
    }
  };
  const handleUSAlert = () => {
    const confirmed = window.confirm(
      "Are you sure you want to send pnl alert for US-Clients?"
    );
    if (confirmed) {
      handleAlertUSPnL();
    }
  };
  const handleReset = () => {
    const confirmed = window.confirm(
      "Are you sure you want to reset amonthlypnl to zero?"
    );
    if (confirmed) {
      handleResetPnL();
    }
  };

  const handlegenerateinvoice = () => {
    const confirmed = window.confirm(
      "Are you sure you want to send invoice to clients?"
    );
    if (confirmed) {
      handleinvoice();
    }
  };

  const handlesendlossreport = () => {
    const confirmed = window.confirm(
      "Are you sure you want to send previous loss alert to clients?"
    );
    if (confirmed) {
      handlelossalert();
    }
  };

  // Function to handle send action for the broadcast message
  const handleSend = async () => {
    try {
      const response = await axios.post(
        "https://www.quatdashboard.com:8084/api/mainTable/send",
        { message: broadcastMessage }
      );
      alert(response.data); // Show success message from backend
      setBroadcastMessage(""); // Clear the text box
      setIsPopupOpen(false); // Close the popup
    } catch (error) {
      alert("Failed to send message."); // Show error message
      console.error("Error sending message:", error);
    }
  };

  const handlelossalert = async () => {
    try {
      const response = await axios.get(
        "https://www.quatdashboard.com:8084/api/mainTable/alert-loss"
      );
      alert(response.data); // Show success message from backend
    } catch (error) {
      alert("Failed to send message."); // Show error message
      console.error("Error sending message:", error);
    }
  };

  // Function to handle Alert P&L button click
  const handleAlertPnL = async () => {
    try {
      const response = await axios.get(
        "https://www.quatdashboard.com:8084/api/mainTable/report"
      );
      alert(response.data); // Show the PnL report for all clients
    } catch (error) {
      alert("Failed to get PnL report."); // Show error message
      console.error("Error fetching PnL report:", error);
    }
  };
  const handleAlertUSPnL = async () => {
    try {
      const response = await axios.get(
        "https://www.quatdashboard.com:8084/api/mainTable/reportus"
      );
      alert(response.data); // Show the PnL report for all clients
    } catch (error) {
      alert("Failed to get PnL report."); // Show error message
      console.error("Error fetching PnL report:", error);
    }
  };

  const handleResetPnL = async () => {
    try {
      const response = await axios.post(
        "https://www.quatdashboard.com:8084/api/mainTable/resetmonthlypnl"
      );
      alert(response, "P&L has been reset for all clients."); // Show success message
    } catch (error) {
      alert("Failed to reset P&L."); // Show error message
      console.error("Error resetting P&L:", error);
    }
  };

  const handleinvoice = async () => {
    try {
      const response = await axios.get(
        "https://www.quatdashboard.com:8084/api/invoices/generateinvoices"
      );

      if (response.status === 200) {
        // Assuming the API call is successful when status is 200
        alert("Invoice sent successfully"); // Display success message
      } else {
        alert("Failed to send invoice."); // Handle unexpected responses
      }
    } catch (error) {
      alert("Failed to send invoice."); // Show error message in case of failure
      console.error("Error sending invoice:", error);
    }
  };

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-item">
          <a className="nav-link" href="/Dashboard">
            <i className="bi bi-grid-fill"></i>
            <span>Dashboard</span>
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/User">
            <i className="bi bi-people-fill"></i>
            <span>Users</span>
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/Report">
            <i className="bi bi-journal-bookmark-fill"></i>
            <span>Reports</span>
          </a>
        </li>
        {/* <li className="nav-item">
          <a className="nav-link" href="/Dashboard">
            <i className="bi bi-person-plus-fill"></i>
            <span>Register</span>
          </a>
        </li> */}
        <li className="nav-item">
          <a className="nav-link" href="/Payment">
            <i className="bi bi-cash-coin"></i>
            <span>Payment</span>
          </a>
        </li>
      </ul>
      <div className="sidebar-buttons">
        <button className="btn alert-btn" onClick={handleAlert}>
          Alert P&L
        </button>
        <button className="btn alert-btn" onClick={handleUSAlert}>
          Alert US P&L
        </button>
        <button className="btn broadcast-btn" onClick={togglePopup}>
          Broadcast
        </button>
        <button className="btn broadcast-btn" onClick={handleReset}>
          Reset P&L
        </button>
        <button className="btn alert-btn" onClick={handlesendlossreport}>
          Loss alert
        </button>
        <button className="btn broadcast-btn" onClick={handlegenerateinvoice}>
          Generate invoice
        </button>
      </div>
      {/* Popup for broadcast message */}
      {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h3>Broadcast Message</h3>
            <textarea
              value={broadcastMessage}
              onChange={handleMessageChange}
              placeholder="Enter your message"
              rows="4"
            />
            <button className="btn send-btn" onClick={handleSend}>
              Send
            </button>
            <button className="btn close-btn" onClick={togglePopup}>
              Close
            </button>
          </div>
        </div>
      )}
    </aside>
  );
}

export default SideBar;
