import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Chart,
  LineElement,
  PointElement,
  LineController,
  BarController,
  CategoryScale,
  LinearScale,
  PieController,
  ArcElement,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom"; // Import the zoom plugin

// Register both Chart.js components and the zoom plugin
Chart.register(
  LineElement,
  PointElement,
  LineController,
  BarController,
  CategoryScale,
  LinearScale,
  PieController,
  ArcElement,
  zoomPlugin // Register the zoom plugin
);

const PerformancePage = () => {
  const { encodedClientId } = useParams(); // Get the Base64-encoded client ID from the URL
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const charts = {}; // Store chart instances
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const customButtonRef = useRef(null);
  const dayreportbutton = useRef(null);
  const [showReportPopup, setShowReportPopup] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [loadingReport, setLoadingReport] = useState(false);
  const [activePicker, setActivePicker] = useState(null);

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      onClick={onClick}
      value={value}
      ref={ref}
      readOnly
      autoComplete="off" // Disable browser autocomplete
      placeholder="Select a date"
      style={{ padding: "10px", fontSize: "16px", width: "100%" }}
    />
  ));

  // const handleCustomButtonClick = () => {
  //   setShowDatePicker(true);
  // };

  // const handleOpenDatePicker = () => {
  //   setStartDate(null);
  //   setEndDate(null);
  //   setShowDatePicker(true);
  // };

  const getCurrentMonthRange = () => {
    const today = new Date();
    const start = new Date(today.getFullYear(), today.getMonth(), 1);
    const end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    setStartDate(start);
    setEndDate(end);
  };

  const getCurrentFinancialYearRange = () => {
    const today = new Date();
    const year =
      today.getMonth() < 3 ? today.getFullYear() - 1 : today.getFullYear();
    const start = new Date(year, 3, 1); // April 1
    const end = new Date(year + 1, 2, 31); // March 31
    setStartDate(start);
    setEndDate(end);
  };

  const getlast7days = () => {
    const today = new Date();
    const start = new Date(today);
    start.setDate(today.getDate() - 7);
    setStartDate(start);
    setEndDate(today);
  };

  const getPreviousFinancialYearRange = () => {
    const today = new Date();
    const year =
      today.getMonth() < 3 ? today.getFullYear() - 2 : today.getFullYear() - 1;
    const start = new Date(year, 3, 1); // April 1 of the previous financial year
    const end = new Date(year + 1, 2, 31); // March 31 of the previous financial year
    setStartDate(start);
    setEndDate(end);
  };
  const getCurrentYearRange = () => {
    const today = new Date();
    const start = new Date(today.getFullYear(), 0, 1); // January 1 of the current year
    const end = new Date(today.getFullYear(), 11, 31); // December 31 of the current year
    setStartDate(start);
    setEndDate(end);
  };

  const getCurrentQuarterRange = () => {
    const today = new Date();
    const quarter = Math.floor((today.getMonth() + 3) / 3);
    const start = new Date(today.getFullYear(), (quarter - 1) * 3, 1);
    const end = new Date(today.getFullYear(), quarter * 3, 0);
    setStartDate(start);
    setEndDate(end);
  };

  const handleCustomButtonClick = () => {
    setStartDate(null);
    setEndDate(null);
    setActivePicker("custom");
    setShowDatePicker(true);
  };

  const handleOpenDatePicker = () => {
    setStartDate(null);
    setEndDate(null);
    setActivePicker("dayreport");
    setShowDatePicker(true);
  };

  const handleCancel = () => {
    setShowDatePicker(false);
    setStartDate(null);
    setEndDate(null);
  };

  const fetchPerformanceData = async () => {
    try {
      // Use the encoded client ID directly in the API endpoint
      const response = await fetch(
        `https://www.quatdashboard.com:8084/api/clientPerformance/total/${encodedClientId}`
      );
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      console.error("Error fetching performance data:", error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect to fetch data when the component mounts or encodedClientId changes
  useEffect(() => {
    fetchPerformanceData();
  }, [encodedClientId]);

  // Handle button click to refetch data
  const handleOverall = () => {
    setLoading(true); // Optionally set loading state before fetching
    fetchPerformanceData();
  };

  const fetchCustomPerformanceData = async () => {
    if (!startDate || !endDate) return; // Ensure both dates are selected

    // const formattedStartDate = startDate.toISOString().slice(0, 10); // "yyyy-MM-dd"
    // const formattedEndDate = endDate.toISOString().slice(0, 10); // "yyyy-MM-dd"
    const toIST = (date) => {
      const offset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
      const istDate = new Date(date.getTime() + offset); // Adjust to IST
      return istDate.toISOString().slice(0, 10); // Format as YYYY-MM-DD
    };

    const formattedStartDate = toIST(startDate);
    const formattedEndDate = toIST(endDate);

    try {
      const response = await fetch(
        `https://www.quatdashboard.com:8084/api/clientPerformance/custom/${encodedClientId}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`
      );
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      console.error("Error fetching custom performance data:", error);
    }
  };

  // Apply button click handler
  const handleApply = () => {
    fetchCustomPerformanceData();
    setShowDatePicker(false);
  };
  const handleApply1 = async () => {
    if (!startDate || !endDate) return; // Ensure dates are selected
    setShowDatePicker(false); // Close the date picker
    setLoadingReport(true); // Show loading indicator for the report

    // const formattedStartDate = startDate.toISOString().slice(0, 10); // Format as YYYY-MM-DD
    // const formattedEndDate = endDate.toISOString().slice(0, 10); // Format as YYYY-MM-DD
    const toIST = (date) => {
      const offset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
      const istDate = new Date(date.getTime() + offset); // Adjust to IST
      return istDate.toISOString().slice(0, 10); // Format as YYYY-MM-DD
    };

    const formattedStartDate = toIST(startDate);
    const formattedEndDate = toIST(endDate);

    try {
      const response = await fetch(
        `https://www.quatdashboard.com:8084/api/clientPerformance/dayreport/${encodedClientId}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch report data");
      }
      const jsonData = await response.json();
      setReportData(jsonData); // Set the fetched data
    } catch (error) {
      console.error("Error fetching day report:", error);
      setReportData({ error: "Failed to load report data." });
    } finally {
      setLoadingReport(false); // Hide the loading indicator
      setShowReportPopup(true); // Open the report popup
    }
  };
  const handleCloseReport = () => {
    setShowReportPopup(false); // Close the Day Report popup
  };

  useEffect(() => {
    if (data) {
      const createChart = (ctxId, type, labels, dataPoints, options) => {
        const ctx = document.getElementById(ctxId).getContext("2d");
        if (charts[ctxId]) charts[ctxId].destroy(); // Destroy existing chart if it exists
        charts[ctxId] = new Chart(ctx, {
          type,
          data: { labels, datasets: dataPoints },
          options: {
            ...options,
            plugins: {
              ...options.plugins,
              legend: { display: false }, // Hide legend for a cleaner look
            },
            backgroundColor: "transparent", // Make chart background transparent
          },
        });
      };

      // ROI Chart
      createChart(
        "roiChart",
        "line",
        Object.keys(data.roiMap || []),
        [
          {
            data: Object.values(data.roiMap || []),
            borderColor: "#4caf50",
            backgroundColor: "rgba(76, 175, 80, 0.2)",
            fill: true,
            tension: 0.4,
            pointRadius: 0.9,
          },
        ],
        {
          responsive: true,
          plugins: {
            legend: { display: false },
            zoom: {
              pan: {
                enabled: true,
                mode: "x", // Enable panning in the x-direction
              },
              zoom: {
                wheel: {
                  enabled: true,
                },
                pinch: {
                  enabled: true,
                },
                mode: "x", // Enable zooming in the x-direction
              },
            },
          },
          scales: {
            x: { title: { display: true, text: "Date" } },
            y: {
              title: { display: true, text: "ROI" },
              ticks: { callback: (value) => value.toFixed(2) },
            },
          },
        }
      );

      // DD Chart
      createChart(
        "ddChart",
        "line",
        Object.keys(data.ddMap || []),
        [
          {
            data: Object.values(data.ddMap || []),
            borderColor: "#f44336",
            backgroundColor: "rgba(244, 67, 54, 0.2)",
            fill: true,
            tension: 0.4,
            pointRadius: 0.9,
          },
        ],
        {
          responsive: true,
          plugins: {
            legend: { display: false },
            zoom: {
              pan: {
                enabled: true,
                mode: "x",
              },
              zoom: {
                wheel: {
                  enabled: true,
                },
                pinch: {
                  enabled: true,
                },
                mode: "x",
              },
            },
          },
          scales: {
            x: { title: { display: true, text: "Date" } },
            y: {
              title: { display: true, text: "Drawdown" },
              ticks: { callback: (value) => value.toFixed(2) },
            },
          },
        }
      );

      // PnL vs Charges Bar Chart
      createChart(
        "pnlChargesChart",
        "bar",
        Object.keys(data.pnlMap || []),
        [
          {
            label: "PNL",
            data: Object.values(data.pnlMap || []),
            backgroundColor: Object.values(data.pnlMap || []).map((value) =>
              value >= 0 ? "#4caf50" : "#f44336"
            ),
          },
          {
            label: "Charges",
            data: Object.values(data.chargesMap || []),
            backgroundColor: "#2196f3",
          },
        ],
        {
          responsive: true,
          plugins: {
            legend: { display: false },
            zoom: {
              pan: {
                enabled: true,
                mode: "x",
              },
              zoom: {
                wheel: {
                  enabled: true,
                },
                pinch: {
                  enabled: true,
                },
                mode: "x",
              },
            },
          },
          scales: {
            x: { title: { display: true, text: "Date" }, stacked: true },
            y: {
              title: { display: true, text: "Amount (in Rs)" },
              stacked: true,
            },
          },
        }
      );

      // Weekday PnL Distribution Pie Chart
      createChart(
        "weekdayPieChart",
        "pie",
        Object.keys(data.weekdayMap || []),
        [
          {
            data: Object.values(data.weekdayMap || []),
            backgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56",
              "#4BC0C0",
              "#9966FF",
              "#FF9F40",
              "#FF6384",
            ],
          },
        ],
        {
          responsive: true,
          plugins: {
            legend: {
              position: "right",
              labels: { boxWidth: 20, padding: 10, font: { size: 14 } },
            },
          },
        }
      );
    }

    // Cleanup function to destroy charts when the component unmounts
    return () => {
      Object.values(charts).forEach((chart) => chart.destroy());
    };
  }, [data]);

  const generateTableRows = (breakupData, type) => {
    return Object.entries(breakupData).map(([year, breaks]) => {
      const breakData =
        type === "weekly" ? breaks.weeklyBreak : breaks.monthlyBreak;
      const percentageData =
        type === "weekly" ? breaks.weeklypercentage : breaks.monthlypercentage;

      const total = Object.values(breakData).reduce(
        (sum, value) => sum + (value || 0),
        0
      );
      const formattedTotal =
        Math.abs(total) < 100000
          ? total.toFixed(2) // Shows the raw number without "K" and with 2 decimal places
          : Math.abs(total) < 10000000
          ? `${(Math.abs(total) / 100000).toFixed(2)}L`
          : `${(Math.abs(total) / 10000000).toFixed(2)}Cr`; // For values above 1 Crore

      return (
        <tr key={year}>
          <td>{year}</td>
          {Object.keys(
            type === "weekly"
              ? {
                  MONDAY: 0,
                  TUESDAY: 0,
                  WEDNESDAY: 0,
                  THURSDAY: 0,
                  FRIDAY: 0,
                  SATURDAY: 0,
                  SUNDAY: 0,
                }
              : {
                  JANUARY: 0,
                  FEBRUARY: 0,
                  MARCH: 0,
                  APRIL: 0,
                  MAY: 0,
                  JUNE: 0,
                  JULY: 0,
                  AUGUST: 0,
                  SEPTEMBER: 0,
                  OCTOBER: 0,
                  NOVEMBER: 0,
                  DECEMBER: 0,
                }
          ).map((key) => {
            const value = breakData[key] || 0;
            const percentage = percentageData ? percentageData[key] : null;

            // Format the value and percentage if the value is not zero
            const formattedValue =
              value !== 0
                ? Math.abs(value) < 100000
                  ? `${(value / 100000).toFixed(2)}L${
                      percentage ? ` (${percentage.toFixed(2)}%)` : ""
                    }`
                  : Math.abs(value) < 10000000
                  ? `${(Math.abs(value) / 100000).toFixed(2)}L${
                      percentage ? ` (${percentage.toFixed(2)}%)` : ""
                    }`
                  : `${(Math.abs(value) / 10000000).toFixed(2)}Cr${
                      percentage ? ` (${percentage.toFixed(2)}%)` : ""
                    }`
                : "0"; // Display "0" for zero values

            return (
              <td key={key} className={value > 0 ? "positive" : "negative"}>
                {formattedValue}
              </td>
            );
          })}
          <td className={total >= 0 ? "positive" : "negative"}>
            {formattedTotal}
          </td>
        </tr>
      );
    });
  };

  if (loading) return <div>Loading...</div>;
  if (!data) return <div>Error loading data</div>;

  return (
    <div>
      <style>{styles}</style>
      <div className="header">
        <div className="profile-container">
          <div className="profile">
            <div className="profile-image">
              {(() => {
                const nameParts = (data.name || "User").split(" ");
                return nameParts.length > 1
                  ? nameParts[0][0] + nameParts[1][0]
                  : (data.name || "User").substring(0, 2);
              })()}
            </div>
            <div className="client-name">{data.name}</div>
          </div>
        </div>
        <div className="report-buttons">
          <button className="report-button" onClick={handleOverall}>
            Overall
          </button>

          <button
            className="report-button"
            onClick={handleCustomButtonClick}
            ref={customButtonRef}
          >
            Custom
          </button>

          <button
            className="report-button"
            onClick={handleOpenDatePicker}
            ref={dayreportbutton}
          >
            Day Report
          </button>
        </div>
      </div>

      {showDatePicker &&
        activePicker === "custom" &&
        customButtonRef.current && (
          <div
            className="date-picker-popup"
            style={{
              position: "absolute",
              top:
                customButtonRef.current.getBoundingClientRect().bottom +
                window.scrollY,
              left:
                customButtonRef.current.getBoundingClientRect().left +
                window.scrollX -
                300, // Adjust this value to position the popup to the left of the button
            }}
          >
            <div>
              {/* <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
        <button onClick={getCurrentMonthRange}>Current Month</button>
        <button onClick={getCurrentFinancialYearRange}>Curr.FY</button>
        <button onClick={getPreviousFinancialYearRange}>Prev.FY</button>
        <button onClick={getCurrentQuarterRange}>Current Quarter</button>
      </div> */}

              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginBottom: "15px",
                  alignItems: "center",
                }}
              >
                <span className="range-link" onClick={getCurrentMonthRange}>
                  curr.Mth
                </span>
                <span>|</span>
                <span
                  className="range-link"
                  onClick={getCurrentFinancialYearRange}
                >
                  curr.FY
                </span>
                <span>|</span>
                <span
                  className="range-link"
                  onClick={getPreviousFinancialYearRange}
                >
                  prev. FY
                </span>
                <span>|</span>
                <span className="range-link" onClick={getCurrentQuarterRange}>
                  curr.Qr
                </span>
                <span>|</span>
                <span className="range-link" onClick={getCurrentYearRange}>
                  curr.Yr
                </span>
              </div>

              <div className="date-picker-container">
                <div className="date-picker-wrapper">
                  <label htmlFor="start-date">Start Date:</label>
                  <DatePicker
                    id="start-date"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Select start date"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    dateFormat="dd/MM/yyyy"
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    customInput={<CustomInput />} // Use custom input with autocomplete off
                  />
                </div>
                <div className="date-picker-wrapper">
                  <label htmlFor="end-date">End Date:</label>
                  <DatePicker
                    id="end-date"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="Select end date"
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    dateFormat="dd/MM/yyyy"
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    customInput={<CustomInput />} // Use custom input with autocomplete off
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                }}
              >
                <button onClick={handleApply} disabled={!startDate || !endDate}>
                  Apply
                </button>
                <button onClick={handleCancel}>Cancel</button>
              </div>
            </div>
          </div>
        )}

      {showDatePicker &&
        activePicker === "dayreport" &&
        dayreportbutton.current && (
          <div
            className="date-picker-popup"
            style={{
              position: "absolute",
              top:
                dayreportbutton.current.getBoundingClientRect().bottom +
                window.scrollY,
              left:
                dayreportbutton.current.getBoundingClientRect().left +
                window.scrollX -
                300, // Adjust this value to position the popup to the left of the button
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                marginBottom: "15px",
                alignItems: "center",
              }}
            >
              <span className="range-link" onClick={getlast7days}>
                last 7 days
              </span>
              <span>|</span>
              <span className="range-link" onClick={getCurrentMonthRange}>
                curr.Mth
              </span>
              <span>|</span>
              <span className="range-link" onClick={getCurrentQuarterRange}>
                curr.Qr
              </span>
            </div>
            <div className="date-picker-container">
              <div className="date-picker-wrapper">
                <label htmlFor="start-date">Start Date:</label>
                <DatePicker
                  id="start-date"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Select start date"
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  dateFormat="dd/MM/yyyy"
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  customInput={<CustomInput />} // Use custom input with autocomplete off
                />
              </div>
              <div className="date-picker-wrapper">
                <label htmlFor="end-date">End Date:</label>
                <DatePicker
                  id="end-date"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholderText="Select end date"
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  dateFormat="dd/MM/yyyy"
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  customInput={<CustomInput />} // Use custom input with autocomplete off
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
              }}
            >
              <button onClick={handleApply1} disabled={!startDate || !endDate}>
                Apply
              </button>
              <button onClick={handleCancel}>Cancel</button>
            </div>
          </div>
        )}
      {showReportPopup && (
        <div
          className="report-popup"
          style={{
            position: "fixed",
            top: "20%",
            left: "50%",
            transform: "translate(-50%, -20%)",
            background: "white",
            padding: "20px",
            width: "600px",
            height: "400px", // Set a constant height
            overflow: "hidden", // Ensure only the content scrolls
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            zIndex: 1000,
            borderRadius: "8px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <h2 style={{ margin: 0 }}>Day Report</h2>
            <span
              onClick={handleCloseReport}
              style={{
                cursor: "pointer",
                fontSize: "18px",
                fontWeight: "bold",
                lineHeight: "1",
                padding: "5px",
              }}
            >
              X
            </span>
          </div>

          {/* Scrollable content */}
          <div
            style={{
              overflowY: "auto",
              height: "calc(100% - 50px)", // Adjust height for header and padding
            }}
          >
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                tableLayout: "fixed", // Ensures consistent column widths
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "1px solid #ddd",
                      padding: "8px",
                      width: "25%",
                    }}
                  >
                    Date
                  </th>
                  <th
                    style={{
                      borderBottom: "1px solid #ddd",
                      padding: "8px",
                      width: "15%",
                    }}
                  >
                    ROI (%)
                  </th>
                  <th
                    style={{
                      borderBottom: "1px solid #ddd",
                      padding: "8px",
                      width: "20%",
                    }}
                  >
                    Max Drawdown
                  </th>

                  <th
                    style={{
                      borderBottom: "1px solid #ddd",
                      padding: "8px",
                      width: "20%",
                    }}
                  >
                    Gross PnL
                  </th>
                  <th
                    style={{
                      borderBottom: "1px solid #ddd",
                      padding: "8px",
                      width: "20%",
                    }}
                  >
                    Net PnL
                  </th>
                  <th
                    style={{
                      borderBottom: "1px solid #ddd",
                      padding: "8px",
                      width: "20%",
                    }}
                  >
                    Charges
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(reportData).map(([date, metrics]) => (
                  <tr key={date}>
                    <td
                      style={{
                        borderBottom: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "left",
                      }}
                    >
                      {date}
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "right",
                      }}
                    >
                      {metrics.dayRoi}
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "right",
                      }}
                    >
                      {metrics.maxDrawdown}
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "right",
                      }}
                    >
                      {metrics.grossPnL}
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "right",
                      }}
                    >
                      {metrics.netPnL}
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "right",
                      }}
                    >
                      {metrics.charges}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <div className="section">
        <h2 className="section-heading">Overall Data</h2>
        <div className="container">
          <div className="card">
            <div className="title">Gross PnL</div>
            <div
              className={`value ${
                data.totalPnL >= 0 ? "positive" : "negative"
              }`}
            >
              Rs. {data.totalPnL?.toLocaleString("en-IN") || "N/A"}
            </div>
          </div>
          <div className="card">
            <div className="title">Net PnL</div>
            <div
              className={`value ${data.netPnL >= 0 ? "positive" : "negative"}`}
            >
              Rs. {data.netPnL?.toLocaleString("en-IN") || "N/A"}
            </div>
          </div>
          <div className="card">
            <div className="title">Total Charges</div>
            <div className="value">
              Rs. {data.totalCharges?.toLocaleString("en-IN") || "N/A"}
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="section-heading">Statistics</div>
        <div class="container">
          <div className="card">
            <div className="title">Net P&L</div>
            <div
              className={`value ${
                data.cummulativeRoi >= 0 ? "positive" : "negative"
              }`}
            >
              {data.cummulativeRoi?.toFixed(2) || "N/A"}%
            </div>
          </div>

          <div class="card">
            <div class="title">Max Drawdown</div>
            <div class="value negative">
              {data.maxDrawdown?.toFixed(2) || "N/A"}%
            </div>
          </div>
          <div class="card">
            <div class="title">Current Drawdown</div>
            <div class="value negative">
              {data.currentDrawdown?.toFixed(2) || "N/A"}%
            </div>
          </div>
          <div class="card">
            <div class="title">Max Profit</div>
            <div class="value positive">
              Rs.{data.maxProfit?.toLocaleString("en-IN") || "N/A"}
            </div>
          </div>
          <div class="card">
            <div class="title">Max Loss</div>
            <div class="value negative">
              Rs.{data.maxLoss?.toLocaleString("en-IN") || "N/A"}
            </div>
          </div>
          <div class="card">
            <div class="title">Win Rate</div>
            <div class="value positive">
              {data.winRate?.toFixed(2) || "N/A"}%
            </div>
          </div>

          <div class="card">
            <div class="title">Total Days</div>
            <div class="value">{data.totalDays || "0"}</div>
          </div>
          <div class="card">
            <div class="title">Win Days</div>
            <div class="value positive">{data.winDays || "0"}</div>
          </div>
          <div class="card">
            <div class="title">Lose Days</div>
            <div class="value negative">{data.loseDays || "0"}</div>
          </div>
          <div class="card">
            <div class="title">Winning Streak</div>
            <div class="value positive">{data.maxWinStreak || "0"}</div>
          </div>
          <div class="card">
            <div class="title">Losing Streak</div>
            <div class="value negative">{data.maxLossStreak || "0"}</div>
          </div>
        </div>
      </div>

      <div className="chart-sections">
        <div className="section">
          <h2 className="section-heading">ROI Chart</h2>
          <div className="chart-container">
            <canvas id="roiChart" className="chart-item"></canvas>
          </div>
        </div>
        <div className="section">
          <h2 className="section-heading">DD Chart</h2>
          <div className="chart-container">
            <canvas id="ddChart" className="chart-item"></canvas>
          </div>
        </div>
        <div className="section">
          <h2 className="section-heading">PNL vs Charges</h2>
          <div className="chart-container">
            <canvas id="pnlChargesChart" className="chart-item"></canvas>
          </div>
        </div>
        <div className="section">
          <h2 className="section-heading">Weekday PnL Distribution</h2>
          <div className="chart-container">
            <canvas
              id="weekdayPieChart"
              className="chart-item large-chart-item"
            ></canvas>
          </div>
        </div>
      </div>

      <div className="breakup-section">
        <h2 className="section-heading">Weekly Breakups</h2>
        <table className="breakup-table">
          <thead>
            <tr>
              <th>Year</th>
              <th>Mon</th>
              <th>Tue</th>
              <th>Wed</th>
              <th>Thu</th>
              <th>Fri</th>
              <th>Sat</th>
              <th>Sun</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>{generateTableRows(data.breakupMap, "weekly")}</tbody>
        </table>
      </div>

      <div className="breakup-section">
        <h2 className="section-heading">Monthly Breakups</h2>
        <table className="breakup-table">
          <thead>
            <tr>
              <th>Year</th>
              <th>Jan</th>
              <th>Feb</th>
              <th>Mar</th>
              <th>Apr</th>
              <th>May</th>
              <th>Jun</th>
              <th>Jul</th>
              <th>Aug</th>
              <th>Sep</th>
              <th>Oct</th>
              <th>Nov</th>
              <th>Dec</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>{generateTableRows(data.breakupMap, "monthly")}</tbody>
        </table>
      </div>
    </div>
  );
};

const styles = `
body {
  font-family: Arial, sans-serif;
  background-color: #7AB2D3;
  // background-color: #ffffff;
  padding: 20px;
  margin: 0;
}
h1 {
  color: #333;
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}
.container {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}
.card {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  background: linear-gradient(135deg, #e0f2ff, #d1e7ff, #f0f7ff);
  transition: transform 0.3s ease;
}
.card:hover {
  transform: translateY(-5px);
}
.title {
  font-size: 14px;
  color: #888;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.value {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}
.positive {
  color: #4caf50;
}
.negative {
  color: #f44336;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: #f9fafb;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  
}

.profile-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.profile {
  display: flex;
  align-items: center;
  gap: 15px;
}

.profile-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #e0e7ff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  color: #3b82f6;
  text-transform: uppercase;
}

.client-name {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.report-buttons {
  display: flex;
  gap: 15px;
  flex-wrap: wrap; /* Allow wrapping */
  justify-content: flex-start;
}

.report-button {
  padding: 7px 14px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  color: white;
  border: none;
  border-radius: 50px;
  background: linear-gradient(135deg, #007bff, #0056b3);
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.3);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.report-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
  transition: transform 0.5s ease;
  transform: skewX(-45deg);
}

.report-button:hover {
  background: linear-gradient(135deg, #0056b3, #003c82);
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(0, 56, 179, 0.4);
}

.report-button:hover::before {
  transform: translateX(100%);
}

.report-button:active {
  transform: translateY(0);
  box-shadow: 0 5px 15px rgba(0, 56, 179, 0.2);
}


.date-picker-popup {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  position: relative;
}

.date-picker-wrapper {
  width: 100%; /* Ensure full-width containers */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.date-picker-wrapper input {
  width: 100%; /* Set consistent width */
  padding: 10px; /* Ensure consistent padding */
  font-size: 16px; /* Same font size for both pickers */
  box-sizing: border-box; /* Include padding in width */
  border: 1px solid #ddd; /* Optional: Consistent border */
  border-radius: 4px;
}

.date-picker-container {
  width: 300px; /* Fixed container width for consistency */
}


.date-picker-wrapper label {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}
.range-link {
  cursor: pointer;
  color: black; /* Default black color */
  text-decoration: none; /* No underline by default */
  padding: 0 5px; /* Optional padding for spacing */
}

.range-link:hover {
  color: blue; /* Blue color on hover */
  text-decoration: none; /* Underline on hover */
}

.range-link:active {
  color: #0047ab; /* Darker blue when clicked */
}

span {
  color: black; /* Ensure the | separator is styled black */
}

.date-picker-popup button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s, transform 0.2s;
  margin-top: 15px;
}

.date-picker-popup button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}
/* Adjust date picker navigation buttons */
.react-datepicker__navigation {
  top: 10px; /* Adjust this value to properly align vertically */
  width: 30px; /* Standardize width */
  height: 30px; /* Standardize height */
  background-color: #007bff;
  border-radius: 50%;
}

.react-datepicker__navigation--previous {
  left: 8px; /* Space between the left edge and button */
}

.react-datepicker__navigation--next {
  right: 8px; /* Space between the right edge and button */
}
  .react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  display: none;
}

.react-datepicker__navigation-icon::before {
  font-size: 18px; /* Set font size for arrows */
  color: white; /* Change arrow color to white for contrast */
}

.section {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.section-heading {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
}
.chart-sections {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
}
    .breakup-table th {
      font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
  }
.chart-sections .section {
  flex: 1;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap");
/* Import a stylish font */
.chart-sections .section .section-heading {
  font-size: 14px;
  color: #888;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 10px;
}
.chart-container {
  display: flex;
  padding: 5px;
  justify-content: center;
  margin-top: 10px;
  border-radius: 8px;
}
.chart-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30vw;
  max-height: 300px;
}
.large-chart-item {
  width: 40vw;
  max-width: 300px;
  height: 40vw;
  max-height: 300px;
}
.breakup-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
.breakup-table th,
.breakup-table td {
  padding: 8px;
  text-align: center;
  border: 1px solid #ddd;
  font-size: 14px;
}
.breakup-table th {
  background-color: #f0f7ff;
  font-weight: bold;
}
.breakup-table .positive {
  color: #4caf50;
}
.breakup-table .negative {
  color: #f44336;
}
.breakup-section {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.section-heading {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  text-align: left;
}
 
@media (max-width: 768px) {
  .chart-container {
    flex-direction: column;
  }
  .chart-item {
    height: 50vw;
    max-height: 250px;
  }
}
@media (max-width: 480px) {
  .chart-item {
    height: 60vw;
    max-height: 200px;
  }
}
`;

export default PerformancePage;
